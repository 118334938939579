const { notification } = require("antd");

type NotificationType = "success" | "info" | "warning" | "error";

export const openNotificationWithIcon = (
  type: NotificationType,
  title: string,
  message: string
) => {
  return notification[type]({
    message: title,
    description: message,
  });
};

export const openErrNotification = (message: string) => {
  return openNotificationWithIcon("error", "抱歉！", message);
};

export const openSuccessNotification = (message: string) => {
  return openNotificationWithIcon("success", "恭喜！", message);
};

export const openWarningNotification = (message: string) => {
  return openNotificationWithIcon(
    "warning",
    "請稍等⋯",
    message
  );
};
