// ./src/stores/index.ts
import { createContext, useContext } from "react";
import { AuthStore, STORE_AUTH } from "./auth";
import { STORE_CAMPAIGN, CampaignStore } from "./campaign";
import { MemberStore, STORE_MEMBER } from "./member";
import { STORE_ROUTER, RouterStore, history } from "./router";

function createStores() {
  return {
    [STORE_ROUTER]: new RouterStore(),
    [STORE_AUTH]: new AuthStore(),
    [STORE_CAMPAIGN]: new CampaignStore(),
    [STORE_MEMBER]: new MemberStore()
  };
}

export const stores = createStores();

export const StoresContext = createContext(stores);

const useStores = () => useContext(StoresContext);

export function useRouterStore() {
  const { routerStore } = useStores();
  return routerStore;
}

export function useAuthStore() {
  const { authStore } = useStores();
  return authStore;
}

export function useCampaignStore() {
  const { campaignStore } = useStores();
  return campaignStore;
}

export function useMemberStore() {
  const { memberStore } = useStores();
  return memberStore;
}

export { history };

export const generalError = { success: false, error: "general_error" };
