import React from "react";
import {
  FacebookShareButton,
  LineShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import { AtFooter } from "./at-footer";

const TITLE = "AsiaTop in LUMIERE FASHION SHOW !";
const URL = "https://campaign.asiatop.space";
const DESC = ``;
export const Preview = (props: any) => {
  const { prize } = props;
  return (
    <div className="drop-shadow-2xl bg-white w-full min-h-[5px] p-10 rounded-2xl mt-5 max-w-[500px] mx-auto">
      {/* <h5 className="text-at-blue leading-5 text-center"> */}
      {/* 恭喜您成為<span className="rainbow"> AT REWARDS </span> */}
      {/* {prize && `會員及獲得${prize.name}！`} */}
      {/* </h5> */}
      {prize && (
        <>
          <h5 className="text-at-blue leading-5 text-center tracking-[2px]">
            Welcome to be our<span className="rainbow"> AT REWARDS </span>member and you've won
            {prize.name}！
          </h5>
        </>
      )}

      {/* When prize is over */}
      {!prize && (
        <h5 className="text-at-blue leading-5 text-center tracking-[2px]">
          <span>
            {/* esline-disable-next-line */}
            🔺Thanks for playing the game and you can access our AT Rewards App to explore more discount coupons!
          </span>
        </h5>
      )}

      <div className="pt-5 text-center w-[150px] mx-auto">
        <img
          src="/assets/QR_Code_1677937926.png"
          alt="qr"
          className="w-[150px] h-[150px] object-fit"
        />
      </div>

      <div className="pt-5 w-[100%] text-center">
        <h5 className="text-at-pink">Share to Friends!</h5>
        <div className="flex flex-row pt-1 items-center justify-center">
          {/* Whatsapp */}
          <WhatsappShareButton
            title={TITLE}
            url={URL}
            style={{ background: "rgba(0,0,0,0)" }}
            children={
              <img
                src="/assets/whatsapp.png"
                className="w-8 h-8 object-fit mr-3"
                alt="whatsapp-share"
              />
            }
          />
          {/* Telegram */}
          <TelegramShareButton
            title={TITLE}
            url={URL}
            style={{ background: "rgba(0,0,0,0)" }}
            children={
              <img
                src="/assets/telegram.png"
                className="w-[30px] h-[30px] object-fit mr-3"
                alt="telegram-share"
              />
            }
          />
          {/* Twitter */}
          <TwitterShareButton
            title={TITLE}
            url={URL}
            hashtags={[
              "AsiaTop",
              "Loyalty",
              "web3",
              "FifaWorldCup",
              "Fifa",
              "WorldCup",
              "Soccer",
              "NFT",
              "blockchain",
            ]}
            style={{ background: "rgba(0,0,0,0)" }}
            children={
              <img
                src="/assets/twitter.png"
                className="w-[30px] h-[30px] object-fit mr-3"
                alt="twitter-share"
              />
            }
          />
          {/* Line */}
          <LineShareButton
            title={TITLE}
            url={URL}
            style={{ background: "rgba(0,0,0,0)" }}
            children={
              <img
                src="/assets/line.png"
                className="w-8 h-8 object-fit mr-3"
                alt="line-share"
              />
            }
          />
          {/* Facebook */}
          <FacebookShareButton
            title={TITLE}
            url={URL}
            quote={DESC}
            style={{ background: "rgba(0,0,0,0)" }}
            children={
              <img
                src="/assets/facebook.png"
                className="w-8 h-8 object-fit mr-3"
                alt="facebook-share"
              />
            }
          />
        </div>
      </div>

      <AtFooter />
    </div>
  );
};
