import React, { useEffect, useState } from "react";
import { Container } from "../../components";
import { GroupStageGame } from "./group-stage-game";
import { LoginForm } from "./login-form";
import { Preview } from "./preview";

export const Home = (props: any) => {
  const [stage, setStage] = useState("login"); // login, game, preview
  const [prize, setPrize] = useState(null);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [stage]);

  return (
    <Container>
      <div className="p-10 z-10 relative">
        <img src="/assets/AT_logo.jpg" className="w-20 h-20 mx-auto" alt="at" />
        <h1 className="text-white text-center pt-3 leading-[40px] text-3xl">
        AsiaTop in<br />LUMIERE FASHION SHOW !
        </h1>

        {stage === "login" && <LoginForm setStage={setStage} />}

        {stage === "preview" && <Preview setStage={setStage} prize={prize} />}

        {stage === "game" && (
          <GroupStageGame
            setStage={setStage}
            setPrize={setPrize}
            prize={prize}
          />
        )}
      </div>
    </Container>
  );
};
