import React, { useState } from "react";
import {
  LoadingIcon,
  openSuccessNotification,
  openWarningNotification,
} from "src/components";
import { useAuthStore, useMemberStore } from "src/stores";
import { Checkbox } from "antd";

const inputClass =
  "mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-at-blue focus:ring-at-blue block w-full rounded-md sm:text-sm focus:ring-1";

export const LoginForm = (props: any) => {
  const { setStage } = props;

  const [countryCode, setCountryCode] = useState("852");
  const [phoneNum, setPhoneNum] = useState("");
  const [otp, setOtp] = useState("");
  const [email, setEmail] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const { getOtpCode, access } = useAuthStore();
  const { updateUserInfo } = useMemberStore()

  const handleInput = (e: any, type: string) => {
    const value = e.target.value;
    if (type === "country") return setCountryCode(value);
    if (type === "phoneNum") return setPhoneNum(value);
    if (type === "otp") return setOtp(value);
  };

  const getCode = async () => {
    if (loading) return;
    setLoading(true);
    if (countryCode === "" || phoneNum === "") {
      return openWarningNotification("Please fill in phone number.");
    }

    const isOtpCodeSent = await getOtpCode(phoneNum, countryCode);
    setLoading(false);
    if (!isOtpCodeSent.success) {
      return openWarningNotification("Please check your existing OTP.");
    }

    return openSuccessNotification("We have sent OTP to your phone number and it will be valid for 5 mins.");
  };

  const verifyCode = async () => {
    if (disabled)
      return openWarningNotification("Please confirm to be our AT Rewards member.");

    if (countryCode === "" || phoneNum === "" || otp === "") {
      return openWarningNotification("Please fill in all details");
    }

    setLoading(true);
    const isLoggedIn = await access({ phoneNum, countryCode, otp });
    email && await updateUserInfo({
      email: email
    })
    setLoading(false);

    if (!isLoggedIn.success) return openWarningNotification("Please try again later.");

    // return setStage('preview') // When finished
    setStage('game')
    return openSuccessNotification("Welcome and start to loot now!");
  };

  return (
    <div className="drop-shadow-2xl bg-white w-full min-h-[500px] p-10 rounded-2xl mt-5 max-w-[500px] mx-auto">
      <h5 className="text-at-blue leading-5 tracking-[2px]">
        Register to be our<span className="rainbow"> AT REWARDS </span>member and start to loot the prize now!
      </h5>

      {!loading && (
        <div>
          <div className="mt-[10px]">
            <p>Phone Number</p>
            <div className="flex flex-row relative">
              <input
                type="text"
                name="country"
                className={`${inputClass} w-[100px] mr-2 pl-6`}
                onChange={(e) => handleInput(e, "country")}
                value={countryCode}
              />
              <p className="text-slate-500 absolute bottom-[10px] left-[10px]">
                +
              </p>
              <input
                type="text"
                name="number"
                className={inputClass}
                onChange={(e) => handleInput(e, "phoneNum")}
                value={phoneNum}
              />
            </div>
          </div>

          <div className="mt-[10px] relative">
            <p>OTP</p>
            <input
              type="text"
              name="code"
              className={`${inputClass} pr-20`}
              onChange={(e) => handleInput(e, "otp")}
              value={otp}
            />
            <p
              className="text-at-pink absolute bottom-[9px] right-[10px] cursor-pointer"
              onClick={() => getCode()}
            >
              GET
            </p>
          </div>

          <div className="relative mt-[10px]">
          <p>Email</p>
          <input
            className={`${inputClass}`}
            placeholder="example@gmail.com"
            value={email}
            onChange={(e: any) => setEmail(e.target.value)}
          />
        </div>
        
          <button
            className="primary mt-5 full py-2"
            onClick={() => {
              verifyCode();
            }}
          >
            <p className="text-white">Access and Start to loot</p>
          </button>

          <div className="mt-5">
            <Checkbox
              checked={!disabled}
              onChange={() => setDisabled(!disabled)}
            >
              <p>Agree to be our AT Rewards member</p>
            </Checkbox>
          </div>
        </div>
      )}

      {loading && (
        <div className="flex h-[100%] items-center justify-center">
          <LoadingIcon />
        </div>
      )}
    </div>
  );
};
