import React from "react";

export const AtFooter = (props: any) => {
  const getMobileOperatingSystem = () => {
    var userAgent = navigator.userAgent;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
      return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent)) {
      return "iOS";
    }

    return "unknown";
  };

  return (
    <div className="pt-5">
      <p className="text-black text-center">前往 AT REWARDS 查看已獲得優惠券</p>
      <div className="mt-3">
        <button
          className="primary full py-2"
          onClick={() => {
            if (getMobileOperatingSystem() === "iOS") {
              return window.open(
                "https://apps.apple.com/au/app/at-rewards/id1530145967"
              );
            }
            if (getMobileOperatingSystem() === "Android") {
              return window.open(
                "https://play.google.com/store/apps/details?id=com.asiatop.loyalty"
              );
            }
            window.open("https://asiatop.space/wallet", "_blank");
          }}
        >
          <p className="text-white">出發前往下載 AT REWARDS</p>
        </button>
        <p
          className="text-at-pink mt-[10px] text-center cursor-pointer"
          onClick={() => {
            window.open("https://asiatop.space/wallet", "_blank");
          }}
        >
          前往網頁版
        </p>
      </div>
    </div>
  );
};
