import React from "react";
const { Modal } = require("antd");

export const CustomModal = (props: any) => {
  const { open, setOpen, content, className } = props;

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <Modal
      className={className}
      visible={open}
      title={null}
      onOk={handleCancel}
      onCancel={handleCancel}
      header={null}
      footer={null}
    >
      {content}
    </Modal>
  );
};
