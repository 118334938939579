import axios from "axios";
import * as Configs from "../config";
import { generalError } from ".";
import { openNotificationWithIcon } from "src/components";
import { t } from "i18next";

export class CampaignStore {
  async getLotteryTime() {
    try {
      const url = `${Configs.apiUrl}/lottery/time`;
      const token = localStorage.getItem("loot-game-token");
      const result: any = await axios.get(url, {
        headers: {
          authorization: `bearer ${token}`,
        },
      });
      if (!result || !result["data"]) return generalError;
      return result["data"];
    } catch (e) {
      let error: any = e;
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        const message = error.response.data.message;
        openNotificationWithIcon("warning", "One more step...", t(message));
        if (message === "jwt_unauthorized" || message === "jwt_bad_request")
          return { success: false, error: "jwt_error" };
      }
      return generalError;
    }
  }

  async increaseLottery() {
    try {
      const url = `${Configs.apiUrl}/lottery/time`;
      const token = localStorage.getItem("loot-game-token");
      const result: any = await axios.post(url, null, {
        headers: {
          authorization: `bearer ${token}`,
        },
      });
      if (!result || !result["data"]) return generalError;
      return result["data"];
    } catch (e) {
      let error: any = e;
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        const message = error.response.data.message;
        openNotificationWithIcon("warning", "One more step...", t(message));

        if (message === "jwt_unauthorized" || message === "jwt_bad_request")
          return { success: false, error: "jwt_error" };
      }
      return generalError;
    }
  }

  async lotteryDraw() {
    try {
      const url = `${Configs.apiUrl}/v2/lottery`;
      const token = localStorage.getItem("loot-game-token");
      const result: any = await axios.post(url, null, {
        headers: {
          authorization: `bearer ${token}`,
        },
      });
      if (!result || !result["data"]) return generalError;
      return result["data"];
    } catch (e) {
      let error: any = e;
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        const message = error.response.data.message;
        openNotificationWithIcon("warning", "One more step...", t(message));

        if (message === "jwt_unauthorized" || message === "jwt_bad_request")
          return { success: false, error: "jwt_error" };
      }
      return generalError;
    }
  }
}

export const STORE_CAMPAIGN = "campaignStore";
