import axios from "axios";
import * as Configs from "../config";
import { generalError } from ".";

export class MemberStore {
  async updateUserInfo(payload: any) {
    const url = `${Configs.apiUrl}/user`;
    const token = localStorage.getItem("loot-game-token");
    try {
      const result: any = await axios.put(url, payload, {
        headers: {
          authorization: `bearer ${token}`,
        },
      });
      if (!result["data"]['success']) return generalError;
      return result["data"];
    } catch (e) {
      return generalError;
    }
  }
}

export const STORE_MEMBER = "memberStore";
