import React, { useEffect, useState } from "react";
import {
  openNotificationWithIcon,
  CustomModal,
  LoadingIcon,
} from "src/components";
import { useCampaignStore } from "../../stores";
import {
  FacebookShareButton,
  LineShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import { AtFooter } from "./at-footer";

const TITLE = "AsiaTop in LUMIERE FASHION SHOW !";
const URL = "https://campaign.asiatop.space";
const DESC = ``;

export const GroupStageGame = (props: any) => {
  const [drawTime, setDrawTime] = useState(0);
  const [clicked, setClicked] = useState(false);
  const { prize, setPrize, setStage } = props;
  const { getLotteryTime, increaseLottery, lotteryDraw } = useCampaignStore();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isShared, setIsShared] = useState(false);

  // getting the lottery chance
  useEffect(() => {
    if (clicked) {
      setTimeout(() => {
        setClicked(false);
        setLoading(true);
        lotteryDraw().then((result) => {
          setLoading(false);
          setPrize(result.coupon || null);
          setDrawTime(drawTime - 1);
          setOpen(true);
          setStage("preview");
        });
      }, 2100);
    }
  }, [clicked, lotteryDraw, drawTime, setPrize, setStage]);

  useEffect(() => {
    getLotteryTime().then((data) => {
      if (data && data.lotteryTime) setDrawTime(data.lotteryTime);
    });
  }, [getLotteryTime]);

  const draw = () => {
    if (drawTime > 0) {
      setClicked(true);
    } else {
      openNotificationWithIcon("error", "Sorry", "You have used up all your chance!");
      setStage("preview");
    }
  };

  const afterShare = () => {
    // return openNotificationWithIcon("info", "系統", "已成功分享"); // Hide below sharing codes
    increaseLottery().then((result) => {
      const { msg } = result;
      switch (msg) {
        case "is_shared":
          openNotificationWithIcon(
            "error",
            "系統",
            "謝謝你的分享，但你稍早前已分享"
          );
          break;
        default:
          openNotificationWithIcon("info", "系統", "已成功分享");
          break;
      }
      getLotteryTime().then((data) => {
        if (data && data.lotteryTime) setDrawTime(data.lotteryTime);
      });
      setOpen(false);
      setIsShared(true);
    });
  };

  return (
    <div className="flex flex-col items-center drop-shadow-2xl bg-white w-full min-h-[500px] p-10 rounded-2xl mt-5 max-w-[500px] mx-auto">
      <h5 className="text-at-blue leading-5 text-center tracking-[2px]">
        [ BOX ] You still have <span className="rainbow">{drawTime}</span> looting chance
      </h5>

      {clicked && !loading && (
        <img
          alt="lottery"
          className="w-[40%]"
          src="/assets/loopbox_final.gif"
        />
      )}
      {!clicked && !loading && (
        <img
          alt="lottery"
          onClick={draw}
          className="w-[40%] cursor-pointer"
          src="/assets/loopbox_final.png"
        />
      )}
      {!loading && (
        <div className="flex flex-col w-[100%] text-center items-center">
          <img
            src="/assets/anchor-point.gif"
            alt="logo"
            className="w-[30px] h-[30px] cursor-pointer"
          />
          <p className="mt-5">Click to Loot</p>
        </div>
      )}

      {loading && <LoadingIcon />}

      <CustomModal
        className={"!w-[95%] sm:!w-[50%]"}
        open={open}
        setOpen={setOpen}
        content={
          <div className="flex flex-col items-center">
            <div className="pt-5">
              {prize && (
                <>
                  <h5 className="text-black mb-3 text-center">恭喜獲得</h5>
                  <h4 className="text-center rainbow">{prize.name}</h4>
                  <img alt="price" className="w-48 m-auto" src={prize.logo} />
                </>
              )}

              {/* When prize is over */}
              {!prize && (
                <h5 className="text-at-blue leading-5 text-center pt-5">
                  <span>
                    {/* esline-disable-next-line */}
                    🔺感謝大家支持，是次活動嘅現金券經已全部派完！
                  </span>
                </h5>
              )}
            </div>

            {!isShared && (
              <div className="pt-5">
                <h5 className="text-black text-center">
                  分享則享有多一次抽獎機會！
                </h5>
                <div className="flex flex-row pt-3 pb-5 items-center justify-center">
                  {/* Whatsapp */}
                  <WhatsappShareButton
                    title={TITLE}
                    url={URL}
                    style={{ background: "rgba(0,0,0,0)" }}
                    children={
                      <img
                        src="/assets/whatsapp.png"
                        className="w-8 h-8 object-fit mr-3"
                        alt="whatsapp-share"
                      />
                    }
                    onShareWindowClose={afterShare}
                  />
                  {/* Telegram */}
                  <TelegramShareButton
                    title={TITLE}
                    url={URL}
                    style={{ background: "rgba(0,0,0,0)" }}
                    children={
                      <img
                        src="/assets/telegram.png"
                        className="w-[30px] h-[30px] object-fit mr-3"
                        alt="telegram-share"
                      />
                    }
                    onShareWindowClose={afterShare}
                  />
                  {/* Twitter */}
                  <TwitterShareButton
                    title={TITLE}
                    url={URL}
                    hashtags={[
                      "AsiaTop",
                      "Loyalty",
                      "web3",
                      "FifaWorldCup",
                      "Fifa",
                      "WorldCup",
                      "Soccer",
                      "NFT",
                      "blockchain",
                    ]}
                    style={{ background: "rgba(0,0,0,0)" }}
                    children={
                      <img
                        src="/assets/twitter.png"
                        className="w-[30px] h-[30px] object-fit mr-3"
                        alt="twitter-share"
                      />
                    }
                    onShareWindowClose={afterShare}
                  />
                  {/* Line */}
                  <LineShareButton
                    title={TITLE}
                    url={URL}
                    style={{ background: "rgba(0,0,0,0)" }}
                    children={
                      <img
                        src="/assets/line.png"
                        className="w-8 h-8 object-fit mr-3"
                        alt="line-share"
                      />
                    }
                    onShareWindowClose={afterShare}
                  />
                  {/* Linkedin */}
                  <LinkedinShareButton
                    title={TITLE}
                    summary={`Join us and play to earn in Fifa World Cup x AsiaTop. We have a perfect prediction price, and direct AT points rewarding to you all joining this campaign!`}
                    url={URL}
                    source={URL}
                    style={{ background: "rgba(0,0,0,0)" }}
                    children={
                      <img
                        src="/assets/linkedin.png"
                        className="w-[30px] h-[30px] object-fit mr-3"
                        alt="linkedin-share"
                      />
                    }
                    onShareWindowClose={afterShare}
                  />
                  {/* Facebook */}
                  <FacebookShareButton
                    title={TITLE}
                    url={URL}
                    quote={DESC}
                    style={{ background: "rgba(0,0,0,0)" }}
                    children={
                      <img
                        src="/assets/facebook.png"
                        className="w-8 h-8 object-fit mr-3"
                        alt="facebook-share"
                      />
                    }
                    onShareWindowClose={afterShare}
                  />
                </div>
                <p className="pt-3 text-center text-at-pink">
                  分享給好友後，請耐心等待，不要刷新畫面﹗我們會自動送上第二次抽獎機會﹗
                </p>
              </div>
            )}

            <AtFooter />
          </div>
        }
      />
    </div>
  );
};
